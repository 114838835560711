<template>
  <div class="inbox">
    <ScrollNotice/>
    <el-container class="inbox container">
      <el-container>
        <el-aside width="275px">
          <div class="header" style="text-align: center">
            
			 
			<img :src="this.apiUrl+this.infos.pic"  style="width:100px"  >	
			
            <el-row class="text-sm">
              <el-col :span="12" class="text-right">
                上次登陆时间：
              </el-col>
              <el-col :span="12" class="text-left">
                2020.11.10
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="12">
                <h3 class="margin-left">{{this.infos.name}}</h3>
              </el-col>
              <el-col :span="12" class="text-left">
                <h3 class="text-light text-sm">(ID: 0000001)</h3>
              </el-col>
            </el-row>

            <el-form class="text-sm" label-width="100px" label-suffix=":">
              <el-form-item label="技术满意度">
                <el-row>
                  <el-col :span="2">0</el-col>
                  <el-col :span="16" class="progress">
                    <el-progress :percentage="90" status="success" :show-text="false"></el-progress>
                  </el-col>
                  <el-col :span="6">4.5分</el-col>
                </el-row>
              </el-form-item>
              <el-form-item label="文明满意度">
                <el-row>
                  <el-col :span="2">0</el-col>
                  <el-col :span="16" class="progress">
                    <el-progress :percentage="90" status="success" :show-text="false"></el-progress>
                  </el-col>
                  <el-col :span="6">4.5分</el-col>
                </el-row>
              </el-form-item>
              <el-button type="primary">收件箱</el-button>
              <el-button type="primary">谁看过我</el-button>
            </el-form>
          </div>

          <h3 class="title margin-top-sm">服务中心</h3>
          <el-menu
              default-active="2"
              router>
            <el-menu-item v-for="(menu, index) in menus" :key="index" :index="menu.url">
              <i :class="menu.icon" v-if="menu.icon"></i>
              <span class="label">{{menu.name}}</span>
            </el-menu-item>
          </el-menu>
        </el-aside>
		
		
		
        <el-main class="body">
          <el-tabs type="border-card">
            <el-tab-pane label="未读信">
              <div class="param">
                <span>全部</span>
                <el-divider direction="vertical"></el-divider>
                <span>按时间</span>
              </div>   

              <div class="list" v-for="i in mess" :key="i">
                <el-row>
                  <el-col :span="4">
                   
					<img :src="i.img" style="width: 100px;"  >
                  </el-col>
				  
				  
                  <el-col :span="14">
                    <h3>{{i.name}}:{{i.content}}</h3>
                    <p class="text-grey desc">
                      <span>{{i.type}}</span>
					   <!-- <span>外科医生</span> -->
                    </p>
                    <div class="text-sm margin-top">
                      <i class="el-icon-time"></i> {{i.create_time}}
                    </div>
                  </el-col>
                  <el-col :span="4" style="line-height: 100px">
                    <span>3封未读</span>
                    <el-divider direction="vertical"></el-divider>
                    <span>共10封</span>
                  </el-col>
                  <el-col :span="2" style="line-height: 100px">
                    <el-button type="primary" @click="toChat(i.uid,i.id)" >查看</el-button>
                  </el-col>
                </el-row>
              </div> 
			  
			  
            </el-tab-pane>
			
			
			
            <el-tab-pane label="已读信">
              <div class="param">
                <span>全部</span>
                <el-divider direction="vertical"></el-divider>
                <span>按时间</span>
              </div>

              <div class="list" v-for="i in mess2" :key="i">
                <el-row>
                  <el-col :span="4">
                    <el-image :src="i.img"  style="width: 100px; height: 70px;" fit="fit"></el-image>
                  </el-col>
                  <el-col :span="14">
                    <h3>{{i.name}}</h3>
                    <p class="text-grey desc">
						
						  <span>{{i.type}}</span>
                   <!--   <span>研究生</span> <span>外科医生</span> -->
                    </p>
                    <div class="text-sm margin-top">
                      <i class="el-icon-time"></i> {{i.create_time}}
                    </div>
                  </el-col>
                <!--  <el-col :span="4" style="line-height: 100px">
                    <span>3封未读</span>
                    <el-divider direction="vertical"></el-divider>
                    <span>共10封</span>
                  </el-col>
                  <el-col :span="2" style="line-height: 100px">
                    <el-button type="primary">查看</el-button>
                  </el-col> -->
                </el-row>
              </div>
            </el-tab-pane>
			
            <el-tab-pane label="已发信">
              <div class="param">
                <span>全部</span>
                <el-divider direction="vertical"></el-divider>
                <span>按时间</span>
              </div>

              <div class="list" v-for="i in 6" :key="i">
                <el-row>
                  <el-col :span="4">
                    <el-image :src="headImg" fit="fit"></el-image>
                  </el-col>
                  <el-col :span="14">
                    <h3>王医生</h3>
                    <p class="text-grey desc">
                      <span>研究生</span> <span>外科医生</span>
                    </p>
                    <div class="text-sm margin-top">
                      <i class="el-icon-time"></i> 2021-01-12 12:00
                    </div>
                  </el-col>
                  <el-col :span="4" style="line-height: 100px">
                    <span>3封未读</span>
                    <el-divider direction="vertical"></el-divider>
                    <span>共10封</span>
                  </el-col>
                  <el-col :span="2" style="line-height: 100px">
                    <el-button type="primary">查看</el-button>
                  </el-col>
                </el-row>
              </div>
            </el-tab-pane>
            <!-- <el-tab-pane label="站内信">
              <div class="param">
                <span>全部</span>
                <el-divider direction="vertical"></el-divider>
                <span>按时间</span>
              </div>

              <div class="list" v-for="i in 6" :key="i">
                <el-row>
                  <el-col :span="4">
                    <el-image :src="headImg" fit="fit"></el-image>
                  </el-col>
                  <el-col :span="14">
                    <h3>王医生</h3>
                    <p class="text-grey desc">
                      <span>研究生</span> <span>外科医生</span>
                    </p>
                    <div class="text-sm margin-top">
                      <i class="el-icon-time"></i> 2021-01-12 12:00
                    </div>
                  </el-col>
                  <el-col :span="4" style="line-height: 100px">
                    <span>3封未读</span>
                    <el-divider direction="vertical"></el-divider>
                    <span>共10封</span>
                  </el-col>
                  <el-col :span="2" style="line-height: 100px">
                    <el-button type="primary">查看</el-button>
                  </el-col>
                </el-row>
              </div>
            </el-tab-pane> -->
          </el-tabs>

        </el-main>
      </el-container>

    </el-container>

  </div>

</template>

<script>
	
  import axios from "axios";
  import ScrollNotice from "../../components/ScrollNotice";

  import {mapState} from 'vuex';

  export default {
    name: "InboxIndex",
    components: {
      ScrollNotice
    },
    data() {
      return {
        headImg: require('../../assets/images/doctor.png'),
		
		mess:[],
		mess2:[],
		mess3:[],
		
		infos:{},
      }
    },
	created() { 
		
		if(!sessionStorage.getItem('uid')){
			this.$router.replace('/login')
		} 	
		const params = new URLSearchParams();
		params.append('uid', sessionStorage.getItem('uid'));  
		params.append('status', 0);  
		axios.post(this.apiUrl+'/api/user/getEmail',params)
		.then((response) => {   
			this.mess = response.data.data;  
					  
		})
		.catch(function(error) {
		    console.log(error);
		}); 
		
		
			const params22 = new URLSearchParams();
			params22.append('uid', sessionStorage.getItem('uid'));  
			params22.append('status', 1);  
			axios.post(this.apiUrl+'/api/user/getEmail',params22)
			.then((response) => {   
				this.mess2 = response.data.data;  
						  
			})
			.catch(function(error) {
			    console.log(error);
			}); 
			
			
			
				const params33 = new URLSearchParams();
				params33.append('uid', sessionStorage.getItem('uid'));  
				params33.append('status', 0);  
				axios.post(this.apiUrl+'/api/user/getEmail',params33)
				.then((response) => {   
					this.mess3 = response.data.data;  
							  
				})
				.catch(function(error) {
				    console.log(error);
				}); 
		
		
		const params2 = new URLSearchParams();
		var query=this.$route.query; 
		this.id = query.id; 
		if(!sessionStorage.getItem('uid')){
			this.infos.pic = '/static/images/1639287252(1).jpg';
		}else{
			params2.append('uid', sessionStorage.getItem('uid')); 
			axios.post(this.apiUrl+'/api/user/getInfo',params2)
			.then((response) => {    
				this.infos =response.data.data;  
				
			}) 
			.catch(function(error) {
			  console.log(error);
			});
		}
		
	
 
		
		
		
			
	},
	
	methods: {
		toChat(did,id){
			
		  //更新已读状态
		  const params = new URLSearchParams();
		  params.append('id', id);  
		  axios.post(this.apiUrl+'/api/user/setEmailStatus',params)
		  .then((response) => {   
		  			  
		  })
		  .catch(function(error) {
		      console.log(error);
		  }); 
		  
			
		  this.$router.push({"name":"在线聊天", query:{did : did}})
		}
	},
	mounted() {
	  this.getHomeList(0);
	  this.getPaidList();
	},
	
    computed: {
      ...mapState(["menus"])
    }
  }
</script>

<style scoped lang="scss">
  .container {
    width: 1200px;
    margin: 20px auto;

    .el-header {
      padding: 0;


    }


    .el-aside {
      background: #ffffff;
      margin-right: 15px;

      .header {
        .el-image {
          width: 100px;
          height: 100px;
          border: 1px solid #E8E8E8;
          margin-top: 20px;
        }

        .text-sm {
          font-size: 12px;
          margin-top: 5px;

          & /deep/ * {
            font-size: 12px !important;
          }
        }

        h3 {
          margin: 0;
          padding: 5px 0;

          &.margin-left {
            margin-left: 30px;
          }
        }

        .text-light {
          font-weight: lighter;
        }

        .progress {
          padding-top: 13px;
        }

        .el-form {
          margin-bottom: 20px;

          .el-form-item {
            margin-bottom: 0px;
            padding-right: 10px;
          }

          .el-button {
            margin-top: 20px;
            width: 100px;
            border-radius: 5px !important;
          }
        }
      }

      .title {
        height: 58px;
        background: #535353;
        color: #ffffff;
        padding: 0px;
        margin: 0px;
        text-align: center;
        line-height: 58px;
        font-size: 22px;

        &.margin-top-sm {
          margin-top: 10px;
        }
      }

      .el-menu {
        .el-menu-item {
          height: 48px;
          line-height: 48px;
          border-bottom: 1px inset;

          /deep/ i:before {
            color: $green !important;
          }
        }

      }
    }

    .body {

      .margin-top {
        margin-top: 10px;
      }

      .el-tabs{
        min-height: 1200px;
      }

      .list {
        border-top: 1px solid #cccccc;
        margin: 10px;
        padding-top: 10px;

        .el-image {
          height: 100px;
        }

        .desc {
          span {
            margin-right: 20px;
          }
        }
      }

      .text-grey {
        color: #999999;
      }

      .text-sm {
        color: #999999;
        font-size: 12px;
      }

    }

  }
</style>
